"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.swedishRISEStrings = void 0;
const swedishRISEStrings = {
  siteTitle: 'Kompetensmatchning',
  signup: {
    usage: 'Hur ska du använda Kompetensmatchning?',
    emailPreference: 'Jag önskar ta emot nyhetsbrev från Kompetensmatchning till den angivna e-postadressen',
    almostReadyText: 'Kompetensmatchning håller på att verifiera ditt organisationsnummer med din personliga information. Vi kommer informera dig via mail när det är klart.'
  },
  offer: {
    frontTitle: 'Sveriges företag behöver din utbildning.',
    frontText: 'Vårt mål är att främja livslångt lärande. Vi vill att företag och organisationer i Sverige ska kunna enkelt hitta era utbildningar så de kan fortsätta att utveckla och fortbilda sina medarbetare.',
    partnerTitle: 'Några företag som redan är kopplade till tjänsten'
  },
  footer: {
    title: 'Kompetensmatchning ÄR EN TJÄNST UTVECKLAD AV RISE',
    description: 'Kompetensmatchning är en tjänst som utvecklas i samverkan mellan RISE och Göteborgsregionens kompetensnav.  RISE Research Institutes of Sweden är Sveriges forskningsinstitut och innovationspartner. RISE är ett oberoende, statligt forskningsinstitut som erbjuder unik expertis och ett 100-tal test- och demonstrationsmiljöer för framtidssäkra teknologier, produkter och tjänster.',
    moreInfo: 'mer information',
    cookies: 'Cookies',
    faq: 'Frågor & svar',
    contact: 'Kontakta oss',
    privacy: 'Personuppgifter',
    web: 'www.ri.se',
    email: 'kontakt@kompetensmatchning.se',
    phone: '010-51 65 193'
  },
  about: {
    pageTitle: 'Om Kompetensmatchning',
    about: 'Detta är Kompetensmatchning',
    aboutText: 'Kompetensmatchning är en mötesplats för kompetensutveckling som primärt riktar sig mot arbetsgivare och utbildningsanordnare som har behov av eller jobbar med kompetensutveckling för yrkesverksamma. Plattformen tar ett avstamp i Västra Götaland och de behov som är identiferade i fordonsindustrin, service och besöksnäringen. Kompetensmatchning är en tjänst som utvecklas i samverkan mellan RISE och Göteborgsregionens kompetensnav. ',
    mission: 'Mål med denna tjänst',
    missionText: 'Vi vill med Kompetensmatchning erbjuda en mötes- och marknadsplats; en mötesplats som ger arbetsgivare och utbildningsanordnare möjlighet att mötas och samverka om tex vilken typ av kompetensutveckling det finns behov av, och en marknadsplats där det är enkelt att hitta rätt typ av utbildning för kompetensutveckling av yrkesverksamma.'
  },
  popup: {
    usage: 'Kompetensmatchningen.se är en plattform för företag som söker eller/och erbjuder utbildningar, du kan alltid ändra denna preferens vid ett senare tillfälle i dina kontoinställningar.'
  },
  learnStartText: 'Välkommen! Här kan du som yrkesverksam söka eller göra efterlysningar för utbildningar som matchar just ditt eller din organisations behov av kompetensutveckling.'
};
exports.swedishRISEStrings = swedishRISEStrings;