"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.englishRISEStrings = void 0;
const englishRISEStrings = {
  siteTitle: 'Kompetensmatchning',
  signup: {
    usage: 'How do you intend to use Kompetensmatchning?',
    emailPreference: 'I wish to receive news letters from Kompetensmatchning on the given email address',
    almostReadyText: 'Kompetensmatchning is verifying your organisationnumber with your personal information. We will inform you when it is ready.'
  },
  offer: {
    frontTitle: 'SWEDISH COMPANIES NEED YOUR EDUCATION',
    frontText: 'Our goal is to promote lifelong learning. We want companies and organizations in Sweden to be able to easily find educations so they can continue to train and further develop their employees.',
    partnerTitle: 'SOME OF THE COMPANIES THAT WENT BEFORE YOU'
  },
  footer: {
    title: 'Kompetensmatchning IS DEVELOPED BY RISE',
    description: 'Komptensmatchning is a service developed in collaboration between RISE and Göteborgsregionens kompetensnav.  RISE research institutes of Sweden is Swedens science and innovation partner. Rise is an independent science institute that offers unique expertise and about 100 test and demonstration-environments for future proof technologies, products and services.',
    moreInfo: 'more information',
    cookies: 'Cookies',
    faq: 'FAQ',
    contact: 'Contact us',
    privacy: 'Privacy',
    web: 'www.ri.se',
    email: 'kontakt@kompetensmatchning.se',
    phone: '010-51 65 193'
  },
  about: {
    pageTitle: 'About Kompetensmatchning',
    about: 'This is Kompetensmatchning',
    aboutText: 'Kompetensmatchning is a test bed for RISE and is used to work with skills resource management for lifelong learning. Kompetensmatchning is free to use. The code is open source and a link to the repo can be found further down.',
    backgroundText: 'Kompetensmatchning was developed in cooperation with Gothenburg Competencehub, Västra Götaland Region and Business Region Gothenburg, with start 2020.',
    missionText: 'With Kompetensmatchning we want to offer a meeting place and a platform for exchange: a meeting place that enables employers and education providers with the possibility to meet and collaborate on for instance which types of competence development is in demand, and a platform for exchange where it is easy to find the right type of education for competence development for professionals.'
  },
  popup: {
    usage: 'Kompetensmatchningen.se is a platform for companies that search or/and offer for educations and courses, you can always change this preference in a your account settings.'
  },
  learnStartText: "Welcome! Here you can search or make requests for training that matches your or your organisation's need for competence development."
};
exports.englishRISEStrings = englishRISEStrings;