import React from 'react'

const FileNotFound = () => {
  return (
    <div>
      404
    </div>
  )
}

export default FileNotFound