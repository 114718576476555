"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "RISEBrand", {
  enumerable: true,
  get: function get() {
    return _RISEBrand.default;
  }
});
Object.defineProperty(exports, "englishRISEStrings", {
  enumerable: true,
  get: function get() {
    return _english.englishRISEStrings;
  }
});
exports.partnerLogos = void 0;
Object.defineProperty(exports, "swedishRISEStrings", {
  enumerable: true,
  get: function get() {
    return _swedish.swedishRISEStrings;
  }
});
var _swedish = require("./strings/swedish");
var _english = require("./strings/english");
var _RISEBrand = _interopRequireDefault(require("./components/RISEBrand"));
var _ = _interopRequireDefault(require("./images/logos/1.png"));
var _2 = _interopRequireDefault(require("./images/logos/2.png"));
var _3 = _interopRequireDefault(require("./images/logos/3.png"));
var _4 = _interopRequireDefault(require("./images/logos/4.png"));
var _5 = _interopRequireDefault(require("./images/logos/5.jpg"));
var _6 = _interopRequireDefault(require("./images/logos/6.png"));
var _7 = _interopRequireDefault(require("./images/logos/7.png"));
var _8 = _interopRequireDefault(require("./images/logos/8.png"));
var _9 = _interopRequireDefault(require("./images/logos/9.png"));
var _rise_logo = _interopRequireDefault(require("./images/logos/rise_logo.png"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const partnerLogos = {
  partner1: _.default,
  partner2: _2.default,
  partner3: _3.default,
  partner4: _4.default,
  partner5: _5.default,
  partner6: _6.default,
  partner7: _7.default,
  partner8: _8.default,
  partner9: _9.default,
  riseLogo: _rise_logo.default
};
exports.partnerLogos = partnerLogos;